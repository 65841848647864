import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Text, Box, Link, Button, Section } from "@quarkly/widgets";
import QuarklycommunityKitMobileSidePanel from "./QuarklycommunityKitMobileSidePanel";
const defaultProps = {
	"padding": "36px 0 36px 0",
	"quarkly-title": "Header",
	"background": "linear-gradient(120deg,#004815 29.39%,#bfcc7b 93.49%)",
	"border-width": "4px"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"empty-border-width": "1px",
			"empty-border-style": "solid",
			"width": "30%",
			"sm-width": "50%",
			"display": "flex",
			"align-items": "center",
			"empty-min-width": "64px",
			"empty-min-height": "64px",
			"empty-border-color": "LightGray"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--headline4",
			"children": "terarivogames"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"empty-min-height": "64px",
			"empty-border-width": "1px",
			"width": "70%",
			"sm-width": "50%",
			"align-items": "center",
			"empty-min-width": "64px",
			"empty-border-style": "solid",
			"empty-border-color": "LightGray",
			"display": "flex"
		}
	},
	"quarklycommunityKitMobileSidePanel": {
		"kind": "QuarklycommunityKitMobileSidePanel",
		"props": {
			"md-z-index": "9"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride": {
		"kind": "Override",
		"props": {
			"slot": "Content",
			"align-items": "center",
			"padding": "16px 0px 16px 16px",
			"md-background": "--color-light",
			"md-z-index": "9",
			"md-justify-content": "flex-start",
			"background": "rgba(255, 255, 255, 0)",
			"justify-content": "center"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride1": {
		"kind": "Override",
		"props": {
			"slot": "Children",
			"justify-content": "center",
			"display": "flex",
			"align-items": "center"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride2": {
		"kind": "Override",
		"props": {
			"slot": "Button Text",
			"md-display": "none"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride3": {
		"kind": "Override",
		"props": {
			"slot": "Button Icon",
			"md-color": "--white",
			"md-font": "36px sans-serif"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride4": {
		"kind": "Override",
		"props": {
			"slot": "Cross",
			"md-color": "--dark",
			"md-font": "36px sans-serif"
		}
	},
	"quarklycommunityKitMobileSidePanelOverride5": {
		"kind": "Override",
		"props": {
			"slot": "Wrapper",
			"md-z-index": "9"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"justify-content": "flex-end",
			"md-flex-direction": "column",
			"md-margin": "40px 0px 13px 0px",
			"md-font": "--headline4"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"href": "#about",
			"font": "normal 600 18px/1.5 --fontFamily-googleInter",
			"text-decoration-line": "initial",
			"color": "--white",
			"margin": "0px 20px 0px 20px",
			"md-margin": "0px 0px 13px 0px",
			"md-font": "--lead",
			"md-color": "--indigo",
			"children": "About"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"transition": "opacity 0.3s ease 0s",
			"md-margin": "0px 0px 13px 0px",
			"href": "#features",
			"font": "normal 600 18px/1.5 --fontFamily-googleInter",
			"text-decoration-line": "initial",
			"hover-opacity": "1",
			"md-font": "--lead",
			"md-color": "--indigo",
			"color": "--light",
			"margin": "0px 20px 0px 20px",
			"children": "Features"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"hover-opacity": "1",
			"md-font": "--lead",
			"md-color": "--indigo",
			"font": "normal 600 18px/1.5 --fontFamily-googleInter",
			"color": "--light",
			"transition": "opacity 0.3s ease 0s",
			"md-margin": "0px 0px 13px 0px",
			"href": "#testimonial",
			"text-decoration-line": "initial",
			"margin": "0px 8px 0px 20px",
			"children": "Reviews"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"margin": "0px 0 0px 20px",
			"type": "link",
			"transition": "background-color 0.3s --transitionTimingFunction-easeInOut 0s",
			"text-decoration-line": "initial",
			"sm-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"md-font": "normal 500 16px/1.6 \"Inter\", sans-serif",
			"sm-padding": "15px 13px 15px 13px",
			"md-text-align": "center",
			"padding": "15px 30px 15px 30px",
			"border-radius": "10px",
			"lg-font": "normal 500 18px/1.6 \"Inter\", sans-serif",
			"md-padding": "15px 16px 15px 16px",
			"hover-color": "--primary",
			"color": "--white",
			"lg-padding": "15px 23px 15px 23px",
			"background": "--color-primary",
			"font": "--lead",
			"hover-background": "--color-white",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-primary",
			"href": "https://play.google.com/store/apps/details?id=com.devolver.grispaid&hl=en"
		}
	}
};

const Header = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" flex-direction="row" />
		<Box {...override("box")}>
			<Text {...override("text")} />
		</Box>
		<Box {...override("box1")}>
			<QuarklycommunityKitMobileSidePanel {...override("quarklycommunityKitMobileSidePanel")}>
				<Override {...override("quarklycommunityKitMobileSidePanelOverride")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride1")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride2")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride3")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride4")} />
				<Override {...override("quarklycommunityKitMobileSidePanelOverride5")} />
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
					<Button {...override("button")}>
						Download
					</Button>
				</Box>
			</QuarklycommunityKitMobileSidePanel>
		</Box>
		{children}
	</Section>;
};

Object.assign(Header, { ...Section,
	defaultProps,
	overrides
});
export default Header;
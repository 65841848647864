import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Strong, Em, LinkBox, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"padding": "80px 0 30px 0",
	"quarkly-title": "Footer-16",
	"background": "linear-gradient(120deg,#004815 29.39%,#bfcc7b 93.49%)"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"margin": "0px 0px 50px 0px",
			"md-margin": "0px 0px 45px 0px",
			"lg-flex-direction": "column"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "20%",
			"lg-margin": "0px 0px 35px 0px",
			"lg-width": "100%",
			"href": "/index"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "--light",
			"font": "--lead",
			"children": <Em
				overflow-wrap="normal"
				word-break="normal"
				white-space="normal"
				text-indent="0"
				text-overflow="clip"
				hyphens="manual"
				user-select="auto"
				pointer-events="auto"
			>
				<Strong
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					terarivogames.
					<br />
					Copyright © 2024.
				</Strong>
			</Em>
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"width": "80%",
			"lg-width": "100%",
			"justify-content": "flex-end",
			"align-items": "center",
			"sm-flex-direction": "column",
			"sm-align-items": "flex-start",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"md-display": "flex",
			"md-grid-gap": "24px"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"align-items": "center",
			"margin": "0px 50px 0px 10px",
			"lg-align-items": "flex-start",
			"justify-content": "flex-end",
			"display": "flex",
			"flex-direction": "row",
			"flex-wrap": "wrap",
			"md-justify-content": "flex-start",
			"sm-margin": "0px 0 30px 0",
			"grid-template-columns": "repeat(3, 1fr)",
			"grid-gap": "8px 0",
			"sm-grid-template-columns": "1fr",
			"md-display": "flex",
			"md-grid-gap": "16px",
			"md-margin": "0px 50px 0px 0px",
			"width": "100%"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"border-color": "--color-primary",
			"display": "flex",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"margin": "0px 1rem 0px 0",
			"lg-border-width": "0px 0px 0px 2px",
			"href": "#about",
			"text-decoration-line": "initial",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"color": "--light",
			"children": <Strong>
				ABOUT
			</Strong>
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"margin": "0px 1rem 0px 0",
			"display": "flex",
			"href": "#features",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"text-decoration-line": "initial",
			"hover-color": "--light",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"color": "--light",
			"children": <Strong>
				FEATURES
			</Strong>
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"margin": "0px 0 0px 0",
			"hover-color": "--light",
			"href": "#testimonial",
			"text-decoration-line": "initial",
			"font": "normal 400 16px/24px --fontFamily-googleRoboto",
			"display": "flex",
			"letter-spacing": "1px",
			"md-margin": "0px 0 0px 0",
			"color": "--light",
			"children": <Strong>
				REVIEWS
			</Strong>
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-flex-direction": "column",
			"md-align-items": "flex-start",
			"justify-content": "space-between",
			"padding": "30px 0px 0px 0px",
			"border-width": "1px 0 0 0",
			"border-style": "solid",
			"border-color": "#2c3339",
			"lg-flex-direction": "column",
			"align-items": "flex-start"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"lg-margin": "0px 0px 20px 0px",
			"sm-flex-direction": "column"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 50px 0px 0px",
			"display": "flex",
			"flex-direction": "column"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "CONTACTS"
		}
	},
	"link3": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"text-align": "left",
			"margin": "0px 0px 5px 0px",
			"hover-color": "white",
			"href": "+61432359989",
			"color": "--lightD1",
			"children": "+61432359989"
		}
	},
	"link4": {
		"kind": "Link",
		"props": {
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "inline-block",
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"hover-color": "white",
			"href": "info@terarivo.com",
			"color": "--lightD1",
			"children": "info@terarivo.com"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"font": "normal 500 18px/1.2 --fontFamily-sansHelvetica",
			"color": "--light",
			"md-margin": "0px 0px 20px 0px",
			"letter-spacing": "1px",
			"children": "ADDRESS"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 20px 0px",
			"font": "normal 300 16px/1.7 --fontFamily-sans",
			"border-color": "#b8acac",
			"md-margin": "0px 0px 15px 0px",
			"color": "--lightD1",
			"children": <>
				unit 19A/87 Catalano Cct,{" "}
				<br />
				Canning Vale WA 6155, Australia
			</>
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-align-self": "stretch",
			"sm-flex-direction": "column"
		}
	},
	"link5": {
		"kind": "Link",
		"props": {
			"padding": "0 0 0 0",
			"margin": "0px 1rem 0px 0",
			"lg-margin": "0px 6px 0px 0px",
			"display": "block",
			"href": "/terms-and-conditions",
			"font": "--base",
			"text-decoration-line": "initial",
			"hover-color": "white",
			"sm-padding": "0 0 15px 0",
			"md-white-space": "nowrap",
			"sm-order": "-1",
			"color": "--light",
			"children": <Strong text-decoration-line="underline">
				Terms and Conditions
			</Strong>
		}
	},
	"link6": {
		"kind": "Link",
		"props": {
			"padding": "0 0 0 0",
			"margin": "0px 0 0px 0",
			"lg-margin": "0px 6px 0px 0px",
			"display": "block",
			"href": "/privacy-policy",
			"font": "--base",
			"text-decoration-line": "initial",
			"hover-color": "white",
			"sm-padding": "0 0 15px 0",
			"md-white-space": "nowrap",
			"sm-order": "-1",
			"color": "--light",
			"children": <Strong text-decoration-line="underline">
				Privacy Policy
			</Strong>
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<LinkBox {...override("linkBox")}>
				<Text {...override("text")} />
			</LinkBox>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Link {...override("link")} />
					<Link {...override("link1")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
		</Box>
		<Box {...override("box3")}>
			<Box {...override("box4")}>
				<Box {...override("box5")}>
					<Text {...override("text1")} />
					<Link {...override("link3")} />
					<Link {...override("link4")} />
				</Box>
				<Box {...override("box6")}>
					<Text {...override("text2")} />
					<Text {...override("text3")} />
				</Box>
			</Box>
			<Box {...override("box7")}>
				<Link {...override("link5")} />
				<Link {...override("link6")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;